import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import One from '../components/Solutions'
import Two from '../components/Two'
import Three from '../components/Three'
import Four from '../components/Four'
import Five from '../components/Five'
import OurPartners from '../components/OurPartners'

class Home extends React.Component {
  render() {
    return (
      <Layout location="/">
        <Helmet
          htmlAttributes={{ lang: 'es' }}
          title="Travelonux"
          meta={[
            { name: 'description', content: 'Software y tecnología para la industria de los viajes y el turismo' },
            { name: 'keywords', content: 'software, tecnología, viajes, turismo, agencia de viajes, operadores, transportistas, gestión, ecommerce' },
          ]}
        ></Helmet>
        <Banner />
        <One />
        <Two />
        <Three />
        <Four />
        <OurPartners />
        <Five />
      </Layout>
    )
  }
}

export default Home
