import React from 'react'
import pic04 from '../assets/images/team.jpg'
import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'

const Three = props => (
  <section
    id="three"
    className="spotlight style3 left inactive"
    style={{ backgroundImage: `url(${pic04})` }}
  >
    <span className="image fit main bottom">
      <img src={pic04} alt="" />
    </span>
    <Fade left big>
      <div className="content">
        <header>
          <h2>TRAVELONUX</h2>
        </header>
        <p>
        Es una Software Factory especializada en la Industria de experiencias Y el Turismo, 
        nacida en diciembre de 2017 con el objetivo de afrontar la necesidad de tecnología especializada en la región, 
        desarrollando una plataforma de autogestión que les permite a las Desarrolladoras de Espectáculos, Tour Operadores, Agencias de viajes,  
        Transportistas y Wholesalers incrementar su participación en el mundo online, optimizar diversos procesos de la operación, 
        bajo un modelo SaaS de rápida implementación y una inversión inicial moderada y accesible.

        </p>
        <ul className="actions">
          <li>
            <a href="/" className="button">
              Nuestro equipo
            </a>
          </li>
        </ul>
      </div>
    </Fade>
    <ScrollLink
      to="four"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)

export default Three
