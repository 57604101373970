import React from 'react'
import Fade from 'react-reveal/Fade'
import pic01 from '../assets/images/amadeus.png'
import pic02 from '../assets/images/FerrereLogo.svg'
import pic03 from '../assets/images/mevueloLogo.svg'
import pic04 from '../assets/images/hotelbedsLogo.png'
import pic05 from '../assets/images/abtour.png'
import pic06 from '../assets/images/staypy.png'
import pic07 from '../assets/images/delta.png'

const Five = props => (
    <section id="five" className="wrapper.fade">
        <Fade duration={2500}>
            <div className="container">
                <header className="minor">
                    <h2>Algunos de nuestros partners:</h2>
                </header>
                <div className="partnersContainer" style={{
                    paddingBottom: `35px`, display: `flex`,
                    justifyContent: `space-around`,
                    alignItems: `center`, flexWrap:`wrap`
                }}>
                    <div className="customContainer">
                        <span className="image fit" style={{ width: `150px`, objectFit:`cover`}}>
                            <img src={pic01} alt="" />
                        </span>
                    </div>
                    <div className="col-2 col-6-xsmall">
                        <span className="image fit" style={{ width: `150px`, objectFit:`cover`}}>
                            <img src={pic02} alt="" />
                        </span>
                    </div>
                    <div className="col-2 col-6-xsmall">
                        <span className="image fit" style={{ width: `150px`, objectFit:`cover`}}>
                            <img src={pic03} alt="" />
                        </span>
                    </div>
                    <div className="col-2 col-6-xsmall">
                        <span className="image fit" style={{ width: `150px`, objectFit:`cover`}}>
                            <img src={pic04} alt="" />
                        </span>
                    </div>
                    <div className="col-2 col-6-xsmall">
                        <span className="image fit" style={{ width: `150px`, objectFit:`cover`}}>
                            <img src={pic05} alt="" />
                        </span>
                    </div>
                    <div className="col-2 col-6-xsmall">
                        <span className="image fit" style={{ width: `150px`, objectFit:`cover`}}>
                            <img src={pic06} alt="" />
                        </span>
                    </div>
                    <div className="col-2 col-6-xsmall">
                        <span className="image fit" style={{ width: `150px`, objectFit:`cover`}}>
                            <img src={pic07} alt="" />
                        </span>
                    </div>
                </div>
            </div>
        </Fade>
    </section>
)
export default Five