import React from 'react'
import { Link as ScrollLink } from 'react-scroll'

const Banner = props => (
  <section id="banner">
    <div className="content" style={{textAlign:`-webkit-center`}}>
      <header style={{backgroundColor:`rgba(23, 24, 32, 0.60)`, borderRadius:`5px`, padding:`15px`}} >
        <h2>POWERING THE TRAVEL INDUSTRY</h2>
        <p>
          Somos una empresa de tecnologia que le permite a las agencias de viajes, tour operadores y transportistas aumentar sus ingresos con presencia omnicanal.
          <br />
          Utilizamos conectividad API, mapeo automatizado y herramientas de optimizacion para ayudar en la transformación de nuestros clientes y en la maximización de sus resultados.
        </p>
      </header>
    </div>
    <ScrollLink
      to="solutions"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)

export default Banner

