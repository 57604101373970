import React from 'react'
import Fade from 'react-reveal/Fade'
import multidispositivoIcon from '../assets/images/multidispositivo.png'
import simpleIcon from '../assets/images/simple.png'
import integralIcon from '../assets/images/integral.png'
import interactivaIcon from '../assets/images/interactiva.png'
import transaccionalIcon from '../assets/images/transaccional.png'
import integradaIcon from '../assets/images/integrada.png'



const Four = props => (
  <Fade up>
    <section id="four" className="wrapper style1 special fade-up">
      <div className="container">
        <header className="major">
          <h2 style={{margin: `0 0 0.2em 0`}}>Plataforma modular</h2>
          <p style={{margin: `0 0 0.2em 0`}}>
          Nuestra plataforma ha sido diseñada a medida utilizando la más avanzada tecnología para su desarrollo 
          facilitando la escalabilidad, integración e incorporación de funcionalidades, de forma modular haciendo posible la sinergia entre productos con diferentes caracteristicas facilitando el cross-selling y garantizando la mejor UX
          </p>
        </header>
        <div className="box alt">
          <div className="row gtr-uniform">
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="fulliconsvg"> <img src={multidispositivoIcon} /></span>
              <h3>Multidispositivo</h3>
              <p>
              Manteniendo la tendencia “MOBILE FIRST” utilizamos las mejores prácticas de PWA (Progressive Web Apps) y Responsive Web
              </p>
            </section>
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="fulliconsvg"> <img src={simpleIcon} /></span>
              <h3>Simple</h3>
              <p>
              Diseñada entorno a la simplicidad de uso, el proceso de compra y consulta asegura los mejores ratios de conversión.
              </p>
            </section>
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="fulliconsvg"> <img src={integralIcon} /></span>
              <h3>Integral</h3>
              <p>
              Todos los componentes y productos se integran a una única plataforma permitiendo una gestión y presentación consistente del contenido.
              </p>
            </section>
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="fulliconsvg"> <img src={interactivaIcon} /></span>
              <h3>Interactiva</h3>
              <p>
              Chat, push-notifications, mailing transaccional y mktg comunicacional
              </p>
            </section>
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="fulliconsvg"> <img src={transaccionalIcon} /></span>
              <h3>Transaccional</h3>
              <p>
              WS a switch de pago local y módulo propio de financiaciones
              </p>
            </section>
            <section className="col-4 col-6-medium col-12-xsmall">
              <span className="fulliconsvg"> <img src={integradaIcon} /></span>
              <h3>Integrada</h3>
              <p>
                Brokers, GDSs, Touroperadores, CarRentals y muchos más
              </p>
            </section>
          </div>
        </div>
        <footer className="major">
          <ul className="actions special">
            <li>
              <a href="/productos" className="button">
                Conoce nuestras soluciones
              </a>
            </li>
          </ul>
        </footer>
      </div>
      
    </section>
  </Fade>
)

export default Four
