import React from 'react'
import pic03 from '../assets/images/omnichannel.jpg'
import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'

const Two = props => (
  <section
    id="two"
    className="spotlight style2 right inactive"
    style={{ backgroundImage: `url(${pic03})` }}
  >
    <span className="image fit main">
      <img src={pic03} alt="" />
    </span>
    <Fade right big>
      <div className="content">
        <header>
          <h2>Un mundo omnicanal</h2>
        </header>
        <p>
        En un mundo en plena era digital es necesario estar más cerca que nunca de nuestros clientes con una estrategia omnicanal, 
        acompañándolo en todas las etapas del proceso de investigación y consulta de nuestros productos, facilitando la información 
        y las herramientas necesarias para brindarles una experiencia de compra poco común en la Industria Turística. 
        Nuestro equipo está formado por personas con amplia experiencia en E-commerce, Operaciones, Gestión Comercial, y 
        Marketing Digital aplicado a la industria de los viajes, event planner, la hotelería y el transporte.
        </p>
        <ul className="actions">
          <li>
            <a href="/productos" className="button">
              Conocé nuestras soluciones
            </a>
          </li>
        </ul>
      </div>
    </Fade>
    <ScrollLink
      to="three"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)

export default Two
