import React from 'react'
import Fade from 'react-reveal/Fade'
import { Link as ScrollLink } from 'react-scroll'
import autogestionIcon from '../assets/images/autogestionweb.png'
import motoresIcon from '../assets/images/motores.png'
import adminIcon from '../assets/images/fileAdmin.png'
import gestionIcon from '../assets/images/gestionProductos.png'

const Four = props => (
  <Fade up>
    <section id="solutions" className="wrapper style1 special fade-up">
      <div className="container">
        <header className="major">
          <h2 style={{margin: `0 0 0.2em 0`}}>Soluciones</h2>
          <p style={{margin: `0 0 0.2em 0`}}>
            Llevá tu negocio al proximo nivel con:
          </p>
        </header>
        <div className="box alt">
          <div className="row gtr-uniform">
            <section className="col-3 col-6-medium col-12-xsmall">
              <span className="fulliconsvg"> <img src={autogestionIcon} /></span>
              <h3>Autogestión de sitio web y posicionamiento en buscadores</h3>
            </section>
            <section className="col-3 col-6-medium col-12-xsmall">
              <span className="fulliconsvg"> <img src={gestionIcon} /></span>
              <h3>Gestión de producto propio</h3>
            </section>
            <section className="col-3 col-6-medium col-12-xsmall">
              <span className="fulliconsvg"> <img src={adminIcon} /></span>
              <h3>Administración de files y facturación online</h3>
            </section>
            <section className="col-3 col-6-medium col-12-xsmall">
              <span className="fulliconsvg"> <img src={motoresIcon} /></span>
              <h3>Potentes motores de reservas</h3>
            </section>
          </div>
        </div>
        <footer className="major">
          <ul className="actions special">
            <li>
              <a href="/productos" className="button">
                Nuestros productos
              </a>
            </li>
          </ul>
        </footer>
        <footer className="major">
          <ul className="actions special">
          <ScrollLink
        to="two"
        className="goto-next"
        activeClass="active"
        smooth={true}
        offset={50}
        duration={1500}
        spy={true}
        style={{PaddingTop:`30px`}}
      >
        Next
      </ScrollLink>
          </ul>
        </footer>

        

      </div>
      
    </section>
  

  </Fade>

)

export default Four
